import React from 'react'

import './mintScreen.scss'

export default function MintScreen({whiteList}) {
  return (
    <div className="mint-container">
        <span className="message">
            {whiteList ? 
                <>
                    <h1>YAY!,</h1>
                    <p>you're on the whitelist.</p>
                </> :
                <>
                    <h1>SORRY,</h1>
                    <p>you're not on the whitelist.</p>
                </>
            }
        </span>
    </div>
  )
}
