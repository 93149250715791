import { useState, useEffect } from 'react'
import { useMetaMask } from "metamask-react"

import './app.scss'
import { MdAccountBalanceWallet } from 'react-icons/md'

import Header from './components/Header/Header'
import Login from './components/Login/Login'
import MintScreen from './components/MintScreen/MintScreen'
import Loader from './components/Loader/Loader'
import { getWhitelist } from './utils/backendApi'

function App() {
  const { status, account } = useMetaMask();
  const { ethereum } = window;

  const [isLoading, setIsLoading] = useState(true);
  const [whiteList, setWhiteList] = useState(false); // whitelist check

  const accountSetup = async () => {
    setIsLoading(true);

    const userWL = await getWhitelist(account);
    setWhiteList(userWL);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  useEffect(() => {
    if(account)
      accountSetup();
  }, [account]);

  return (
    <div className="app">
      <Header />
      <main>
        {account && <span className="account"><MdAccountBalanceWallet /><h2>{account.slice(0, 5) + "..." + account.slice(-4)}</h2></span>}
        <div className="main-inner-border">
          <div className="main-inner">
            {status === "connected" ?
              !isLoading ?
                <MintScreen whiteList={whiteList} /> :
                <Loader />
              : <Login />
            }
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
