import React from 'react'

import './header.scss'

import logo from '../../assets/logo.png'

export default function Header() {
  return (
    <div className="header">
        <img src={logo} alt="Knitty NFT Logo" />
    </div>
  )
}
