import React from 'react'
import { useMetaMask } from 'metamask-react'

import './login.scss'

import { MdOutlineErrorOutline } from 'react-icons/md'

import Loader from '../Loader/Loader'

export default function Login() {
    const { connect, status } = useMetaMask();

    if (status === 'connecting' || status === 'initializing')
        return <Loader />

    else if (status === 'notConnected')
        return (
            <div className="login-container">
                <button className="connect-btn" onClick={connect}>
                    CONNECT
                </button>
            </div>
        )

    else if (status === 'unavailable')
        return (
            <div className="login-container">
                <button className="connect-btn" onClick={() => window.open("https://metamask.io/", "_blank")}>
                    <MdOutlineErrorOutline /> INSTALL METAMASK
                </button>
            </div>
        )
}
