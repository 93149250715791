import axios from "axios";

const BASE_URL = "https://knittynftmint.0xytocin.online/";

export const getWhitelist = async (address) => {
  try {
    const { data } = await axios.post(BASE_URL + "signer/unique", {
      address: address,
    });
    console.log(data.signature[1]);
    return data;
  } catch (err) {
    if(err.response.data === "Not Found")
      console.log("PUBLIC USER");
    else
      console.error("Backend-error: " + err.response.data);
    
      return false;
  }
};