import React from 'react'

import './loader.scss'

import { GiWool } from 'react-icons/gi'

export default function Loader() {
  return (
    <div className="loader-container">
        <GiWool />
        <span className="shadow" />
    </div>
  )
}
